@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");
* {
  box-sizing: border-box;
  
  margin: 0;
  padding: 0;
  font-family: "Montserrat", sans-serif !important;
}



.menu-label {
  font-size: 13px;
}

button {
  right: 20px;
}
hr {
  margin-top: 0px;
  margin-bottom: 0px;
  opacity: 0.15;
}

